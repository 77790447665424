import React from "react"
import { graphql } from "gatsby"

import { StoryWithQuickLinks } from "@hurleymc/components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "../components/link"
import PageBuilder, { getPropHtmlContent } from "../components/page-builder"

const Home = ({ data }) => {
  const { craft } = data
  const { entries } = craft
  const entry = entries[0]
  const image =
    entry.hfImage && entry.hfImage.length > 0 && entry.hfImage[0].lg
      ? entry.hfImage[0].lg
      : null
  // ! Have to map fields for the components
  entry.quickLinks = entry.quickLinks.map(ql => ({
    ...ql,
    summary: getPropHtmlContent(ql.summary),
  }))
  return (
    <Layout>
      <SEO title={"Home"} image={image} />
      <StoryWithQuickLinks
        components={{ Link }}
        image={entry.hfImage}
        linkText={entry.linkText}
        quickLinks={entry.quickLinks}
        storyStatement={entry.storyStatement}
        storyStatementColor={entry.storyStatementColor}
        to={entry.to}
      />
      {entry && entry.pageBuilder && (
        <PageBuilder
          pageBuilder={entry.pageBuilder}
          location={{ pathname: entry.uri }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query Home($id: [Craft_QueryParameter]!) {
    craft {
      entries(site: "hurleyFoundation", id: $id) {
        __typename
        title
        uri
        ... on Craft_hfHome_hfHome_Entry {
          hfImage {
            ... on Craft_AssetInterface {
              title
              url
              lg: url(transform: "splashLg")
              xl: url(transform: "splashXl")
            }
          }
          storyStatement
          storyStatementColor
          linkText
          to {
            ...Link
          }
          quickLinks {
            ... on Craft_quickLink_quickLink_Entry {
              icon
              id
              header
              summary
              to {
                ...Link
              }
            }
          }
          pageBuilder {
            ...PageBuilder
          }
        }
      }
    }
  }
`

export default Home
